/* eslint-disable */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// React & Gatsby
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Jumbotron from 'react-bootstrap/Jumbotron'
// import Button from 'react-bootstrap/Button';
// import Image from 'react-bootstrap/Image';

// Custom
import Page from "../components/Layout/Page"
import Products from '../components/Content/Products';


// Date & Styles
import "../assets/scss/App.scss"
import Hero from "../components/Hero";


class PremiumIconsPage extends Component {
    render() {
        const heading = <h1>Free Vector Icons & Illustrations</h1>;
        const subheading = <h2>Royalty-free vector icons & illustrations for web and apps</h2>;
        return (
            <Page hero={<Hero heading={heading} subheading={subheading} />}>
                <Container className={'mt-4 mb-4'}>
                    <Products category={"free-icons"} colWidth={6} />
                </Container>
            </Page>
        )
    }
}

export default PremiumIconsPage
